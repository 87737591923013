<template>
  <div>
    <div class="pc trial-button-container">
      <div class="h8 sub">가격안내</div>
      <div class="box-price-info">
        <div v-for="(sc_item, sc_idx) in option.subscriptions" :key="`sc-${sc_idx}`" class="item-price-info">
          <div class="flex-align">
            <img src="/static/icon/u_check.svg" class="svg-primary" />
            <div class="size-14 sub">{{ sc_item.date_unit + '개월' }}</div>
          </div>
          <div class="size-16">
            <template v-if="sc_item.discount_type === 0 && sc_item.discount_rate > 0">
              <span class="margin-right-8 size-13 sub4 text-line-through">{{ option.price | currency }}</span>
              <span class="margin-right-4 primary body4-bold">{{ sc_item.discount_rate }}%</span>
            </template>
            <span class="weight-700 main">{{ sc_item.price | currencyNum }}</span>
            <span class="size-14 sub">원</span>
            <span class="size-14 sub3">/월</span>
          </div>
        </div>
      </div>
      <div class="padding-top-40">
        <button class="button is-primary body2-bold" @click="clickOrder" style="width: 100%; height: 52px">
          14일 무료체험
        </button>
        <div class="body4 sub2 text-center margin-top-16">
          무료체험기간 동안 플랜 결제 하지 않으면 서비스가 삭제됩니다.
        </div>
      </div>
    </div>

    <div class="mobile trial-button-container">
      <div class="body3-bold sub">가격안내</div>
      <div class="box-price-info">
        <div v-for="(sc_item, sc_idx) in option.subscriptions" :key="`sc-${sc_idx}`" class="item-price-info">
          <div class="flex-align">
            <img src="/static/icon/u_check.svg" class="svg-primary" />
            <div class="size-14 sub">{{ sc_item.date_unit + '개월' }}</div>
          </div>
          <div class="size-16">
            <template v-if="sc_item.discount_type === 0 && sc_item.discount_rate > 0">
              <span class="margin-right-8 size-13 sub4 text-line-through">{{ option.price | currency }}</span>
              <span class="margin-right-4 primary body4-bold">{{ sc_item.discount_rate }}%</span>
            </template>
            <span class="weight-700 main">{{ sc_item.price | currencyNum }}</span>
            <span class="size-14 sub">원</span>
            <span class="size-14 sub3">/월</span>
          </div>
        </div>
      </div>
      <div class="fixed-bottom-button">
        <button class="button is-primary body2-bold" @click="clickOrder" style="width: 100%; height: 48px">
          14일 무료체험
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserAPIMixin from '../../mixins/UserAPIMixin';

export default {
  name: 'TrialButton',
  mixins: [UserAPIMixin],
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      order_product: undefined,
    };
  },
  created() {
    this.init();
  },
  computed: {
    option() {
      // 옵션 중 Basic PC+
      return this.product.selectors[0].options[1];
    },
  },
  methods: {
    init() {
      this.order_product = {
        product_id: this.product.id,
        quantity: 1,
        discount_price: 0,
        product_price: 0,
        total_price: 0,
        product: this.cloneItem(this.product),
        order_product_option: [],
        required_option_price: 0,
        date_unit: 0,
      };
    },
    clickOrder() {
      this.$store.commit('setBasket', this.order_product);
      if (this.isLogin) {
        this.routeGa('', this.product.title, '무료체험');
        this.routerPush('/service_survey');
      } else {
        this.$store.commit('setPrevPath', this.$route.fullPath);
        this.$store.commit('setTrial', true);
        this.toast('로그인 후 진행 할 수 있습니다.');
        this.$router.replace('/signup');
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.option-st
  border 1px solid $gray1
  border-radius 8px
.pc
  .trial-button-container
    // padding-top 20px
    // border-top 1px solid $gray1
    // margin-top 20px
  .option-st
    padding 16px 24px
    margin-top 16px
  .option-st:hover
    border 1px solid $sub3

.mobile
  .option-st
    padding 12px 16px
    margin-top 8px

.disabled
  background-color #eee

.selected
  border 2px solid $primary !important

.plan-select-wrapper
  display grid
  grid-gap 12px
  grid-template-columns repeat(2, 1fr)

.box-price-info
  margin 12px 0 24px
  border 1px solid $gray1
  padding 12px
  .item-price-info
    display flex
    align-items center
    justify-content space-between
    padding 8px 0
    border-bottom 1px solid $gray2
    img
      width 16px
      margin-right 4px
  .item-price-info:first-child
    padding-top 0
  .item-price-info:last-child
    padding-bottom 0
    border-bottom 0

.fixed-bottom-button
  position fixed
  z-index 10
  bottom 0
  left 0
  width 100%
  border-top 1px solid $gray1
  padding 12px
  background-color white
</style>
